import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FormattedMessage } from "react-intl";

export const Separator = (props) => (
  <Row>
    <Col>
      <div style={{ minHeight: props.height }}></div>
    </Col>
  </Row>
);

export const IntlMessage = (props) => {
  let vals = props.values ? props.values : {};
  return (
    <FormattedMessage
      id={props.id}
      values={{
        ...{
          b: (...chunks) => <b>{chunks}</b>,
          strong: (...chunks) => <strong>{chunks}</strong>,
          i: (...chunks) => <i>{chunks}</i>,
          em: (...chunks) => <em>{chunks}</em>,
          br: <br />,
        },
        ...vals,
      }}
    />
  );
};

export const getHost = () => {
  return process.env.NODE_ENV === `development`
    ? "https://app.placelogg.eu/api"
    : "https://app.placelogg.com/api";
};

export const getLocale = () => {
  let locale = navigator.language;
  return locale.split("-")[0];
};
