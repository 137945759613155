import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { IntlProvider } from "react-intl";
import "bootstrap/dist/css/bootstrap.min.css";

let loc = navigator.language;

const ensureIntlSupport = (loc) => {
  if (window.Intl) return Promise.resolve();

  return new Promise((resolve) => {
    resolve(import("intl"));
  }).then(() => Promise.all([import("intl/locale-data/jsonp/" + loc + ".js")]));
};

function loadMessages() {
  let fl = "en";

  switch (loc.split("-")[0]) {
    case "de":
      fl = "de";
      break;
    case "it":
      fl = "it";
      break;
    case "fr":
      fl = "fr";
      break;
    default:
      fl = "en";
      break;
  }

  import("./translations/" + fl + ".json")
    .then((module) => module.default)
    .then((messages) => renderApp(messages));
}

function renderApp(msgs) {
  //addLocaleData([...de]);
  //console.log(msgs);
  const messages = msgs;
  ReactDOM.render(
    <React.StrictMode>
      <IntlProvider locale={loc} messages={messages}>
        <App />
      </IntlProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

ensureIntlSupport(loc).then(loadMessages);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
