import React, { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { useIntl } from "react-intl";

import "./App.css";
import logo from "./assets/48hticket.png";
import placelogg from "./assets/PLACELOGG_HELLERHG.png";
import { v4 as uuidv4 } from "uuid";
import { Separator, IntlMessage, getHost, getLocale } from "./utils.js";

let enabled = false;

const App = () => {
  const intl = useIntl();

  const [form, setForm] = useState({ accept: false });
  const [supporters, setSupporters] = useState();
  const [industries, setIndustries] = useState();
  const [contactId, setContactId] = useState("");
  const [alert, setAlert] = useState({
    msg: "",
    show: false,
    variant: "primary",
  });

  const hiddenFileInput = React.useRef(null);

  useEffect(() => {
    setContactId(uuidv4());

    fetch(
      getHost() +
        "/dropdown?tag=placelogg_" +
        "industry" +
        "&language=" +
        getLocale()
    )
      .then((res) => res.json())
      .then((data) => {
        setIndustries(data.properties);
      })
      .catch((e) =>
        setAlert({
          msg: e.message,
          show: true,
          variant: "danger",
        })
      );

    fetch(getHost() + "/get48hsupporter")
      .then((res) => res.json())
      .then((data) => {
        setSupporters(data.supporter);
      })
      .catch((e) =>
        setAlert({
          msg: e.message,
          show: true,
          variant: "danger",
        })
      );
  }, []);

  const changeForm = (field, value) => {
    let frm = { ...form };

    frm[field] = value;
    setForm(frm);
  };

  const sendForm = () => {
    if (enabled) {
      fetch(getHost() + "/newcontact", {
        method: "POST",
        headers: new Headers({
          "content-type": "application/json",
        }),
        body: JSON.stringify({
          ...form,
          ...{
            contact_id: contactId,
            language: getLocale(),
            context: "48hticket",
          },
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setAlert({
            msg: intl.formatMessage({ id: data.msg }),
            show: true,
            variant: data.code === 200 ? "primary" : "danger",
          });
        })
        .catch((e) =>
          setAlert({
            msg: e.message,
            show: true,
            variant: "danger",
          })
        );
    }
  };

  enabled = true;
  if (!form.accept) enabled = false;
  if (!form.company) enabled = false;
  if (!form.city) enabled = false;
  if (!form.industry) enabled = false;
  if (!form.firstname) enabled = false;
  if (!form.lastname) enabled = false;
  if (!form.phone) enabled = false;
  if (!form.email) enabled = false;

  return (
    <Container className="p-3">
      <Row>
        <Col sm={8} md={2}>
          <a href="https://www.placelogg.com">
            <img className="placelogg" src={placelogg} alt="placelogg" />
          </a>
        </Col>
        <Col sm={8} md={8}>
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </Col>
        <Col sm={8} md={2}></Col>
      </Row>
      <Row>
        <Col>
          <h1 className="header">
            <IntlMessage id="heading.prevention" />
          </h1>
        </Col>
      </Row>
      <Separator height={100} />
      <Row>
        <Col>
          <span className="heading">
            <IntlMessage id="heading.idea" />
          </span>
          <br />
          <span className="standard">
            ​<IntlMessage id="text.idea" />
          </span>
        </Col>
      </Row>
      <Separator height={100} />
      <Row>
        <Col>
          <span className="heading">
            ​<IntlMessage id="heading.concept" />
          </span>
          <br />
          <span className="standard">
            ​<IntlMessage id="text.concept" />
          </span>
        </Col>
      </Row>
      <Separator height={100} />
      <Row>
        <Col>
          <span className="colored">
            <a href="https://www.placelogg.com">Mehr Infos...</a>
          </span>
        </Col>
        <Col>
          <span className="colored">
            <a href="https://image.placelogg.com/48h_ticket.pdf">
              oder Download PDF...
            </a>
          </span>
        </Col>
        <Col>
          <span className="colored">
            <a href="https://youtu.be/PppQ7dHtaSI">oder Fernsehen...</a>
          </span>
        </Col>
      </Row>
      <Separator height={10} />
      <Row>
        <Col>
          <span className="colored">
            <IntlMessage id="heading.community" />
          </span>
        </Col>
      </Row>
      <Separator height={10} />
      <Row>
        {Array.isArray(supporters) &&
          supporters.map((obj, i) => (
            <Col sm={8} md={4} key={i}>
              {obj.company}
              {obj.city ? ", " + obj.city : ""}
              {obj.logo && (
                <div className="container">
                  <img
                    src={"https://image.placelogg.com/logos/" + obj.logo}
                    alt={obj.company}
                  />
                </div>
              )}
              <br />
              <br />
            </Col>
          ))}
      </Row>
      <Separator height={10} />
      <Row>
        <Col>
          <span className="standard">
            <IntlMessage id="text.community" />
          </span>
          <Alert
            variant={alert.variant}
            show={alert.show}
            onClose={() =>
              setAlert({
                msg: "",
                show: false,
                variant: "primary",
              })
            }
            closeLabel={intl.formatMessage({ id: "alert.close" })}
            dismissible={true}
          >
            {alert.msg}
          </Alert>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              sendForm();
            }}
          >
            <Row>
              <Col sm={8} md={4}>
                <Form.Group controlId="formCompany">
                  <Form.Control
                    type="text"
                    placeholder={
                      intl.formatMessage({ id: "form.company" }) + "*"
                    }
                    onChange={(e) => {
                      changeForm("company", e.target.value);
                    }}
                    value={form.company ? form.company : ""}
                  />
                </Form.Group>
              </Col>
              <Col sm={8} md={4}>
                <Form.Group controlId="formCity">
                  <Form.Control
                    type="text"
                    placeholder={intl.formatMessage({ id: "form.city" }) + "*"}
                    onChange={(e) => {
                      changeForm("city", e.target.value);
                    }}
                    value={form.city ? form.city : ""}
                  />
                </Form.Group>
              </Col>
              <Col sm={8} md={4}>
                <Form.Group controlId="formIndustry">
                  <Form.Control
                    as="select"
                    placeholder={
                      intl.formatMessage({ id: "form.industry" }) + "*"
                    }
                    onChange={(e) => {
                      changeForm("industry", e.target.value);
                    }}
                    value={form.industry ? form.industry : ""}
                  >
                    <option value="">
                      {intl.formatMessage({ id: "form.nooption" })}
                    </option>
                    {Array.isArray(industries) &&
                      industries.map((obj, i) => (
                        <option value={obj.propertyid} key={i}>
                          {obj.label}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8} md={6}>
                <Form.Group controlId="formFirstname">
                  <Form.Control
                    type="text"
                    placeholder={
                      intl.formatMessage({ id: "form.firstname" }) + "*"
                    }
                    onChange={(e) => {
                      changeForm("firstname", e.target.value);
                    }}
                    value={form.firstname ? form.firstname : ""}
                  />
                </Form.Group>
              </Col>
              <Col sm={8} md={6}>
                <Form.Group controlId="formLastname">
                  <Form.Control
                    type="text"
                    placeholder={
                      intl.formatMessage({ id: "form.lastname" }) + "*"
                    }
                    onChange={(e) => {
                      changeForm("lastname", e.target.value);
                    }}
                    value={form.lastname ? form.lastname : ""}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8} md={6}>
                <Form.Group controlId="formEmail">
                  <Form.Control
                    type="email"
                    placeholder={intl.formatMessage({ id: "form.email" }) + "*"}
                    onChange={(e) => {
                      changeForm("email", e.target.value);
                    }}
                    value={form.email ? form.email : ""}
                  />
                </Form.Group>
              </Col>
              <Col sm={8} md={6}>
                <Form.Group controlId="formPhone">
                  <Form.Control
                    type="text"
                    placeholder={intl.formatMessage({ id: "form.phone" }) + "*"}
                    onChange={(e) => {
                      changeForm("phone", e.target.value);
                    }}
                    value={form.phone ? form.phone : ""}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8} md={12}>
                <Form.Group controlId="formRemark">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={intl.formatMessage({ id: "form.remark" })}
                    onChange={(e) => {
                      changeForm("remark", e.target.value);
                    }}
                    value={form.remark ? form.remark : ""}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={8} md={12}>
                <Form.Group controlId="formAccept">
                  <Form.Check
                    onChange={(e) => {
                      changeForm("accept", !form.accept);
                    }}
                    checked={form.accept === true ? true : false}
                    type="checkbox"
                    label={intl.formatMessage({ id: "form.accept" }) + "*"}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              variant="primary"
              type="submit"
              disabled={enabled !== true ? true : false}
            >
              ​<IntlMessage id="form.send" />
            </Button>
          </Form>
        </Col>
      </Row>
      <Separator height={20} />
      <Row>
        <Col sm={8} md={12}>
          <Form.Group>
            <Button
              variant="primary"
              disabled={form.accept !== true ? true : false}
              onClick={() => hiddenFileInput.current.click()}
            >
              ​<IntlMessage id="form.upload" />
            </Button>
            <Form.File
              id="formFile"
              style={{ display: "none" }}
              ref={hiddenFileInput}
              accept="image/*"
              onChange={() => {
                const fileInput = document.querySelector("#formFile");
                const formData = new FormData();

                formData.append("file", fileInput.files[0]);

                const options = {
                  method: "POST",
                  body: formData,
                };

                fetch(getHost() + "/uploadlogo/" + contactId, options)
                  .then((res) => res.json())
                  .then((data) =>
                    setAlert({
                      msg: intl.formatMessage({ id: data.msg }),
                      show: true,
                      variant: data.code === 200 ? "primary" : "danger",
                    })
                  )
                  .catch((e) =>
                    setAlert({
                      msg: e.message,
                      show: true,
                      variant: "danger",
                    })
                  );
              }}
              label="Logo upload"
            />
          </Form.Group>
        </Col>
      </Row>
      <Separator height={50} />

      <Row>
        <Col>
          <span className="footer">
            <a href="https://www.placelogg.com/impressum">
              <IntlMessage id="footer.imprint" />
            </a>
          </span>
        </Col>
        <Col>
          <span className="footer">
            <a href="https://www.placelogg.com/datenschutz">
              <IntlMessage id="footer.dsgvo" />
            </a>
          </span>
        </Col>
      </Row>
      <Separator height={20} />
      <Row>
        <Col sm={8} md={3}>
          <span className="footer">
            <IntlMessage id="footer.copyright" />
          </span>
        </Col>
        <Col sm={8} md={3}>
          <span className="footer">
            <IntlMessage id="footer.company" />
          </span>
        </Col>
        <Col sm={8} md={3}>
          <span className="footer">
            <IntlMessage id="footer.phone" />
          </span>
        </Col>
        <Col sm={8} md={3}>
          <span className="footer">
            <IntlMessage id="footer.email" />
          </span>
        </Col>
      </Row>
    </Container>
  );
};

export default App;
